import { useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  DataTableWrapper,
  Button,
  AvatarList,
  coreThemes,
  ModalContainer,
  ButtonDropdown,
  Ellipsis,
  useLoading,
  fes,
  AiIcon
} from 'simple-core-ui'
import { openLink, hasModule } from 'utils/helpers'
import s from './InvoiceList.scss'
import { FaExclamationTriangle } from 'react-icons/fa'
import { IoWarningOutline } from 'react-icons/io5'
import ACT from '../actions'
import APP_ACT from 'app/actions'
import REVIEW_ACT from 'reviews/review_ladder/actions'
import { Tabs } from './Tabs'
import { Summary } from './Summary'
import moment from 'moment'
import { ActionsPopover } from './ActionsPopover'
import { InvoiceListFilters } from './Filters'
import swal from 'sweetalert'
import axios from 'axios'
import { UploadProgress } from './UploadProgress'
import { makeGetRequest, makePostRequest } from 'utils/api'
import { serializeParams, formatPreferenceSettings, serializeBulkRejectData } from './serializers'
import { BULK_ACTIONS, DIALOG_BULK_ACTIONS, BULK_WARNING_MESSAGES } from '../constants'

import { INVOICE_AI_LABELS } from './constants'
import ReviewModalContainer from 'reviews/review_ladder/containers/ReviewModalContainer'
import { invoiceScoped } from 'reviews/review_ladder/scopedProps'
import ReasonsForm from 'reviews/review_ladder/components/ReasonsForm'
import { Params, Column } from 'simple-core-ui/containers/Core/DataTableContainer/DataTableWrapper'
import { Fields, InvoiceListParams, PreferenceSetting } from 'invoices/types'

type GenericTableCell = Record<string, unknown>
interface InvoiceRows {
  results: { id: number }[]
  total?: number
}

type BulkSelectAction = (option: { label: string; value: unknown; isDisabled?: boolean }) => void

type BulkSelectOption = {
  approve: Record<string, string>
  mark_paid: Record<string, string>
}

interface State {
  invoices: {
    invoiceList: InvoiceRows
    invoiceListParams: InvoiceListParams
    invoiceListFragment: string
  }
  app: {
    credentials: {
      editVendorGroupsEnabled: boolean
      purchaseOrdersEnabled: boolean
      custom: {
        names: {
          legal_entity_management: string[]
          matter_group_management: string[]
        }
      }
    }
    loading: {
      INVOICE_LIST_FETCH: boolean
    }
  }
}

const REJECTION_SETTINGS_URL = '/invoices/rejection/settings/'
const initialBulkRejectFields = {
  reasons: [],
  additionalDetails: ''
}

const hasCustomizableColumns = hasModule('has_invoice_list_customized_columns')

const InvoicesListContainer = () => {
  const dispatch = useDispatch()
  const [isLoadingSettings, withLoadingLocks] = useLoading()
  const invoices = useSelector<State, InvoiceRows>(state => state.invoices.invoiceList)
  const params = useSelector<State, InvoiceListParams>(state => state.invoices.invoiceListParams)
  const fragment = useSelector<State, string>(state => state.invoices.invoiceListFragment)
  const isLoading = useSelector<State, boolean>(state => state.app.loading.INVOICE_LIST_FETCH)
  const {
    legal_entity_management: legalEntityManagement,
    matter_group_management: matterGroupManagement
  } = useSelector<State, { legal_entity_management: string[]; matter_group_management: string[] }>(
    state => state.app.credentials.custom.names
  )

  const { editVendorGroupsEnabled, purchaseOrdersEnabled } = useSelector<
    State,
    { editVendorGroupsEnabled: boolean; purchaseOrdersEnabled: boolean }
  >(state => state.app.credentials)

  const [selectedTab, setSelectedTab] = useState<string>(params.category)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [allRowsSelected, setAllRowsSelected] = useState(false)
  const [isUploadProgressModalVisible, setIsUploadProgressModalVisible] = useState(false)
  const [percent, setPercent] = useState(0)
  const [action, setAction] = useState('')
  const [refetch, setRefetch] = useState(0)
  const [showSingleRejectModal, setShowSingleRejectModal] = useState(false)
  const [showBulkRejectModal, setShowBulkRejectModal] = useState(false)
  const [bulkRejectFields, setBulkRejectFields] = useState(initialBulkRejectFields)
  const [preferenceSetting, setPreferenceSetting] = useState<PreferenceSetting | null>(null)
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null)
  const [vendorId, setVendorId] = useState<number | null>(null)

  const { modalMessageFetcher } = invoiceScoped

  const updateBulkRejectFields = (fields: any) => {
    setBulkRejectFields(prev => ({ ...prev, ...fields }))
  }

  useEffect(() => {
    const fetchPreferenceSetting = async () => {
      try {
        const { settings } = await withLoadingLocks(makeGetRequest(REJECTION_SETTINGS_URL))
        setPreferenceSetting(formatPreferenceSettings(settings) as PreferenceSetting)
      } catch (error) {
        dispatch({
          type: APP_ACT.PUSH_NOTIFICATION,
          payload: {
            title: 'Error',
            message: 'There was an issue fetching Preference Settings.',
            level: 'error'
          }
        })
      }
    }
    showBulkRejectModal && fetchPreferenceSetting()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBulkRejectModal])

  const allRequests = async (action: string, cb: (arg: number[]) => void) => {
    const toRemove: number[] = []
    const payload = action === 'reject' ? serializeBulkRejectData(bulkRejectFields) : null
    for (const id of selectedRows) {
      try {
        await axios.post(`/invoices/${id}/${action}`, payload)
        setPercent(percent => percent + 1)
      } catch (e) {
        toRemove.push(id)
        setPercent(percent => percent + 1)
      }
    }

    const finalArr = selectedRows.filter((r: number) => !toRemove.includes(r))

    cb && cb(finalArr)

    const actionLabel =
      action === 'approve' ? 'approve' : action === 'reject' ? 'reject' : 'be marked as paid'

    if (toRemove.length) {
      dispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Error',
          message: `One or more invoices failed to ${actionLabel}. Please try again or contact Support if the issue persists.`,
          level: 'error'
        }
      })
    }

    // this request is fired to update the session
    const serialized = serializeParams(params)
    await makePostRequest(`/invoices/v2/list/${params.category}/`, serialized)
  }

  useEffect(() => {
    const baseUrl = window.location.href.split('#')[0]
    if (fragment) {
      window.location.replace(`${baseUrl}#${fragment}`)
      try {
        window.localStorage.setItem('savedInvoiceList', fragment)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Local Storage is full. Please empty data')
      }
    }
  }, [fragment])

  const openRejectModal = (id: number, vendorId: number) => {
    setSelectedRowId(id)
    setVendorId(vendorId)
    setShowSingleRejectModal(true)
  }

  const markPaid = (id: number) => {
    dispatch({
      type: ACT.INVOICE_MARK_PAID_REQUESTED,
      loadingLock: 'on',
      payload: {
        id
      }
    })
  }

  const approveInvoice = (id: number) => {
    dispatch({
      type: ACT.INVOICE_APPROVE_REQUESTED,
      loadingLock: 'on',
      payload: {
        id,
        params
      }
    })
  }

  const columns: Column[] = useMemo(() => {
    return [
      ...(selectedTab !== 'Approved'
        ? [
            {
              columnKey: 'approvers',
              isDefault: true,
              content: 'Approvers',
              isSortable: false,
              isFilterable: false,
              render: (cell: { content: { label: string }[] }) => {
                return (
                  <AvatarList
                    limit={3}
                    entries={cell.content.map(c => ({
                      label: c.label
                    }))}
                  />
                )
              }
            }
          ]
        : []),
      {
        columnKey: 'received_date',
        isDefault: true,
        content: 'Received Date',
        isSortable: true,
        isFilterable: true,
        render: (cell: { content: Date }) => {
          return moment(cell.content).format('M/D/YYYY')
        }
      },
      {
        columnKey: 'invoice_date',
        isDefault: true,
        content: 'Invoice Date',
        isSortable: true,
        isFilterable: true,
        render: (cell: { content: Date }) => {
          return moment(cell.content).format('M/D/YYYY')
        }
      },
      {
        columnKey: 'invoice_number',
        isDefault: true,
        content: 'Invoice Number',
        isSortable: true,
        render: (cell: { content: string }, row: { id: number }) => {
          const content = cell.content
          return (
            <a href={`/invoices/${row.id}/?fromInvoiceList=true`}>
              <Ellipsis>{content}</Ellipsis>
            </a>
          )
        }
      },
      {
        columnKey: 'matters',
        isDefault: true,
        content: 'Matter',
        isSortable: false,
        render: (cell: { content: { name: string; id: number }[] }) => {
          const str = cell.content.map(r => r.name).join(', ')
          if (cell.content.length === 1) {
            const url = `/v2/matters/${cell.content[0].id}/`
            return (
              <a href={url}>
                <Ellipsis>{cell.content[0].name}</Ellipsis>
              </a>
            )
          }
          return <Ellipsis>{str}</Ellipsis>
        }
      },
      {
        columnKey: 'vendor',
        isDefault: true,
        content: 'Vendor',
        isSortable: true,
        render: (cell: { content: { id: number; name: string } }) => {
          const name = cell.content.name
          return (
            <a href={`/invoices/vendors/${cell.content.id}/?fromInvoiceList=true`}>
              <Ellipsis>{name}</Ellipsis>
            </a>
          )
        }
      },
      {
        columnKey: 'invoice_total',
        isDefault: true,
        content: 'Amount',
        isSortable: true,
        style: { textAlign: 'right' }
      },
      {
        columnKey: 'flags',
        isDefault: true,
        content: 'Violation',
        isSortable: false,
        style: {
          textAlign: 'center'
        },
        render: (
          cell: { content: string[] },
          row: { ai_flagged_category_codes: string[]; ai_flag: boolean }
        ) => {
          const violationFlags = cell.content.map(r => r).join(', ')
          const aiFlags = row.ai_flagged_category_codes
            ? row.ai_flagged_category_codes.map(flag => INVOICE_AI_LABELS[flag]).join(', ')
            : []

          return (
            <div style={{ display: 'flex', gap: '4px' }}>
              {row.ai_flag && <AiIcon size={20} data-tip={aiFlags} />}
              {violationFlags && (
                <div data-tip={violationFlags} className={s.iconWrapper}>
                  <FaExclamationTriangle className={s.warningIcon} />
                </div>
              )}
            </div>
          )
        }
      },
      {
        columnKey: 'approvable',
        isDefault: true,
        content: 'Approvable',
        isSortable: false,
        render: (_: GenericTableCell, row: { approvable: boolean }) => {
          return row.approvable ? 'Yes' : 'No'
        }
      },
      {
        columnKey: 'status',
        isDefault: true,
        content: 'Status',
        isSortable: false,
        render: (
          cell: { content: string },
          row: { id: number; approvable: boolean; vendor: { id: number } }
        ) => {
          if (selectedTab !== 'Mine') {
            if (selectedTab === 'Approved') {
              return (
                <div className={s.statusApprovedContent}>
                  <span className={s.statusLabel}>{cell.content}</span>
                  {cell.content !== 'Paid' && (
                    <Button style={{ padding: 4 }} onClick={() => markPaid(row.id)} isPrimary>
                      Mark Paid
                    </Button>
                  )}
                </div>
              )
            }
            return cell.content
          }
          return row.approvable ? (
            cell.content === 'Received' ? (
              <div style={{ display: 'flex' }}>
                <span
                  className={s.rejectBtn}
                  onClick={() => openRejectModal(row.id, row.vendor.id)}
                >
                  Reject
                </span>{' '}
                <Button style={{ padding: 4 }} isPrimary onClick={() => approveInvoice(row.id)}>
                  Approve
                </Button>
              </div>
            ) : (
              cell.content
            )
          ) : null
        }
      },
      {
        columnKey: 'date_approved',
        content: 'Date Approved',
        isSortable: true,
        isFilterable: true,
        render: (cell: { content: string }) => {
          if (cell.content === '----') return cell.content
          return moment(cell.content).format('M/D/YYYY')
        }
      },
      {
        columnKey: 'adjustments',
        content: 'Invoice Adjustments',
        isSortable: false,
        isFilterable: false
      },
      {
        columnKey: 'resubmitted',
        content: 'Invoice Resubmitted',
        isSortable: false,
        isFilterable: false
      },
      {
        columnKey: 'subject',
        content: 'Invoice Subject',
        isSortable: false,
        isFilterable: false,
        render: (cell: { content: string }) => {
          return <Ellipsis>{cell.content}</Ellipsis>
        }
      },
      {
        columnKey: 'legal_entities',
        content: <Ellipsis width={200}>{legalEntityManagement[0]}</Ellipsis>,
        contentLabel: legalEntityManagement[0],
        isSortable: false,
        isFilterable: false,
        render: (cell: { content: string[] | string }) => {
          if (Array.isArray(cell.content)) {
            return <Ellipsis>{cell.content.join(', ')}</Ellipsis>
          } else {
            return <Ellipsis>{cell.content}</Ellipsis>
          }
        }
      },
      {
        columnKey: 'matter_groups',
        content: <Ellipsis width={200}>{matterGroupManagement[0]}</Ellipsis>,
        contentLabel: matterGroupManagement[0],
        isSortable: false,
        isFilterable: false,
        render: (cell: { content: string[] }) => {
          if (Array.isArray(cell.content)) {
            return <Ellipsis>{cell.content.join(', ')}</Ellipsis>
          } else {
            return <Ellipsis>{cell.content}</Ellipsis>
          }
        }
      },
      {
        columnKey: 'billing_end_date',
        content: 'Period End Date',
        isSortable: true,
        isFilterable: true,
        render: (cell: { content: Date }) => {
          return moment(cell.content).format('M/D/YYYY')
        }
      },
      {
        columnKey: 'billing_start_date',
        content: 'Period Start Date',
        isSortable: true,
        isFilterable: true,
        render: (cell: { content: Date }) => {
          return moment(cell.content).format('M/D/YYYY')
        }
      },
      ...(purchaseOrdersEnabled
        ? [
            {
              columnKey: 'purchase_order',
              content: 'Purchase Order',
              isSortable: false,
              isFilterable: false,
              render: (cell: { content: string }) => {
                return <Ellipsis>{cell.content}</Ellipsis>
              }
            }
          ]
        : []),
      ...(editVendorGroupsEnabled
        ? [
            {
              columnKey: 'vendor_group',
              content: 'Vendor Group',
              isSortable: false,
              isFilterable: false,
              render: (cell: { content: string }) => {
                return <Ellipsis>{cell.content}</Ellipsis>
              }
            }
          ]
        : [])
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, fragment])

  const results = useMemo(() => {
    const { results, total } = invoices

    return {
      columns,
      rows: results,
      totalEntries: total,
      filteredTotal: total
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, invoices])

  const performAction = (action: string, reduxAction: string) => {
    setIsUploadProgressModalVisible(true)
    allRequests(action, finalArr => {
      dispatch({
        type: ACT[reduxAction],
        payload: {
          ids: finalArr
        }
      })
    })
  }

  const rejectInvoice = (formFields: Record<string, unknown>) => {
    dispatch({
      type: ACT.INVOICE_REJECT_REQUESTED,
      loadingLock: 'on',
      payload: { id: selectedRowId, params, formFields }
    })
    setShowSingleRejectModal(false)
    setSelectedRowId(null)
    setVendorId(null)
  }

  const closeSingleRejectModal = () => {
    setSelectedRowId(null)
    setVendorId(null)
    setShowSingleRejectModal(false)
  }

  const closeBulkRejectModal = () => {
    setShowBulkRejectModal(false)
    setBulkRejectFields(initialBulkRejectFields)
  }

  const resetRowSelection = () => {
    setSelectedRows([])
    setAllRowsSelected(false)
  }

  const updateTable = (updatedParams: Params) => {
    resetRowSelection()
    dispatch({
      type: ACT.INVOICE_LIST_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: {
        params: {
          page: updatedParams.page,
          pageSize: updatedParams.pageSize,
          search: updatedParams.search,
          ordering: updatedParams.ordering,
          filters: params.filters,
          category: selectedTab
        }
      }
    })
  }

  useEffect(() => {
    dispatch({
      type: ACT.INVOICE_LIST_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: {
        params
      }
    })

    dispatch({
      type: ACT.INVOICE_SUMMARY_FETCH_REQUESTED,
      loadingLock: 'on'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch])

  const downloadPdf = (id: number) => {
    openLink(`/invoices/${id}/format/pdf`)
  }

  const exportCsv = (id: number) => {
    openLink(`/invoices/${id}/format/csv2`)
  }

  const downloadBulkPdf = () => {
    openLink(`/invoices/v2/bulk_pdf/?invoice_ids=${selectedRows.join(',')}`)
  }

  const downloadBulkCsv = () => {
    openLink(`/invoices/v2/bulk_csv/?invoice_ids=${selectedRows.join(',')}`)
  }

  const downloadBulkAttachments = () => {
    openLink(`/invoices/v2/bulk_invoice_files_download/?invoice_ids=${selectedRows.join(',')}`)
  }

  const resendApprovalEmails = async (number: number, id: number) => {
    const willResend = await swal({
      title: 'Confirm Resend Approval Emails',
      text: `Resend Approval emails for ${number}?`,
      buttons: ['Cancel', 'Confirm']
    })

    if (willResend) {
      dispatch({
        loadingLock: 'on',
        type: ACT.RESEND_APPROVAL_EMAILS_REQUESTED,
        payload: { invoiceId: id }
      })
    }
  }

  const renderCustomAction = (row: { id: number; status: string; invoice_number: number }) => (
    <ActionsPopover
      rowId={row.id}
      downloadPdf={downloadPdf}
      exportCsv={exportCsv}
      hasResendNotification={row.status === 'Approved'}
      resendApprovalEmails={(id: number) => resendApprovalEmails(row.invoice_number, id)}
    />
  )

  const onSearch = ({ filterParams: updatedFilterParams }: { filterParams: Params }) => {
    resetRowSelection()
    dispatch({
      type: ACT.INVOICE_LIST_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: {
        params: {
          pageSize: params.pageSize,
          search: params.search,
          ordering: params.ordering,
          page: 1,
          filters: updatedFilterParams,
          category: selectedTab
        }
      }
    })
  }

  const selectRow = ({ id }: { id: number }) => {
    setSelectedRows(prevSelectedRows => {
      const index = prevSelectedRows.indexOf(id)
      if (index !== -1) {
        const newSelectedRows = [...prevSelectedRows]
        newSelectedRows.splice(index, 1)
        return newSelectedRows
      } else {
        return [...prevSelectedRows, id]
      }
    })
  }

  const selectAllRows = () => {
    setAllRowsSelected(allRowsSelected => !allRowsSelected)
    if (allRowsSelected) {
      setSelectedRows([])
    } else {
      setSelectedRows(invoices.results.map(i => i.id))
    }
  }

  const changeTab = (tab: string) => {
    dispatch({ type: ACT.INVOICE_LIST_RESET })
    setSelectedTab(tab)
    resetRowSelection()
    dispatch({
      type: ACT.INVOICE_SUMMARY_FETCH_REQUESTED,
      loadingLock: 'on'
    })
  }

  const handleBulkActionSelect = async (option: { value: string }) => {
    const isDialog = DIALOG_BULK_ACTIONS.some(x => x === option.value)
    if (isDialog) {
      const warningMsg = BULK_WARNING_MESSAGES(selectedRows.length)[
        option.value as keyof BulkSelectOption
      ]
      const willPay = await swal({
        title: warningMsg.title,
        text: warningMsg.text,
        buttons: ['Cancel', 'Yes'],
        icon: 'warning'
      })

      if (willPay) {
        setAction(option.value)
        performAction(option.value, warningMsg.reduxAction)
      }
    } else if (option.value === 'reject') {
      setAction(option.value)
      setShowBulkRejectModal(true)
    } else if (option.value === 'DownloadPdf') {
      downloadBulkPdf()
    } else if (option.value === 'DownloadAttachments') {
      downloadBulkAttachments()
    } else {
      downloadBulkCsv()
    }
  }

  const bulkButton = (
    <ButtonDropdown
      value={null}
      options={BULK_ACTIONS(selectedTab)}
      onSelect={handleBulkActionSelect as BulkSelectAction}
      displayText={`Bulk Actions (${selectedRows.length})`}
      listStyles={{ width: '105%' }}
    />
  )

  return (
    <section>
      <Summary />
      <div className="box" style={{ marginBottom: 280 }}>
        <div className="box-content" style={{ minHeight: '70vh' }}>
          <Tabs
            hasConfirmation={selectedRows.length}
            selectedTab={selectedTab}
            setSelectedTab={changeTab}
          />
          <DataTableWrapper
            params={params}
            customSearchOptions={{
              placeholder: 'Matter, inv. number, vendor. Start typing to search.',
              styles: { width: 270, fontStyle: 'italic' }
            }}
            remotePagination
            tableHeight="500px"
            categories={[]}
            rows={results.rows}
            columns={
              hasModule('has_invoice_list_customized_columns')
                ? results.columns
                : results.columns.filter(c => c.isDefault)
            }
            totalEntries={results.totalEntries}
            filteredTotal={results.filteredTotal}
            updateTable={updateTable}
            panelStyles={{ border: 'none', padding: '0', boxShadow: 'none' }}
            customAction={renderCustomAction}
            hasActions
            hasTooltip
            bulkDismiss
            isLoading={isLoading}
            filters={
              <InvoiceListFilters
                hasConfirmation={selectedRows.length}
                selectedTab={selectedTab}
                filterParams={params.filters}
                onSearch={onSearch}
              />
            }
            selectAllRows={selectAllRows}
            selectRow={selectRow}
            selectedRows={new Set(selectedRows)}
            allRowsSelected={allRowsSelected}
            theme={coreThemes.EB}
            checkboxSize="md"
            bulkActions={bulkButton}
            customizableColumns={
              hasCustomizableColumns ? `invoiceListColumns.${selectedTab}` : undefined
            }
          />

          {isUploadProgressModalVisible && (
            <ModalContainer
              title={`${
                action === 'approve'
                  ? 'Approving'
                  : action === 'reject'
                  ? 'Rejecting'
                  : 'Marking as paid'
              } ${fes(selectedRows.length, 'invoices')}`}
              size="md"
              confirmText="Close"
              confirmCb={() => {
                setIsUploadProgressModalVisible(false)
                resetRowSelection()
                setPercent(0)
                setRefetch(r => r + 1)
                action === 'reject' && closeBulkRejectModal()
              }}
              isDisabled={percent !== selectedRows.length}
              hideCancelBtn
              hasCloseIcon={false}
              content={
                <UploadProgress action={action} percent={percent} total={selectedRows.length} />
              }
            />
          )}
          {showSingleRejectModal && (
            <ReviewModalContainer
              modalAction={REVIEW_ACT.REJECTION_REQUESTED}
              scope="invoice"
              scopeId={selectedRowId}
              vendorId={vendorId}
              modalMessageFetcher={modalMessageFetcher}
              onConfirmWithNotify={rejectInvoice}
              onCancel={closeSingleRejectModal}
            />
          )}
          {showBulkRejectModal && (
            <ModalContainer
              title={`Reject ${fes(selectedRows.length, 'Invoices')}`}
              size="lg"
              confirmText="Reject"
              isLoading={isLoadingSettings}
              confirmCb={() => performAction(action, ACT.SELECTED_INVOICES_REJECTED)}
              cancelText="Cancel"
              cancelCb={closeBulkRejectModal}
              isDisabled={
                (preferenceSetting?.invoice_rejection_reason_preference?.value &&
                  !bulkRejectFields.reasons.length) ||
                isLoadingSettings
              }
              content={
                <>
                  <ReasonsForm
                    fields={bulkRejectFields as Fields}
                    updateFields={updateBulkRejectFields}
                    preferenceSetting={preferenceSetting}
                    isRejection
                  />
                  <div className={s.warningLine}>
                    <IoWarningOutline />
                    <p>Email notifications will not be sent for bulk rejections</p>
                  </div>
                </>
              }
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default InvoicesListContainer
